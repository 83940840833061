import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css"; // or 'ant-design-vue/dist/antd.less'
import ElementUI from "element-ui"; // 导入elementui
import "element-ui/lib/theme-chalk/index.css";
import "../permission"; // 导入permission
import "@/assets/css/common.scss"; // 导入全局reset样式
import "@/assets/css/tailwind.css"; // 导入tailwind
import "./assets/iconFont/iconfont.css"; // 引入iconfont图标
import "./assets/iconFont/iconfont.js";
import SlideVerify from "vue-monoplasty-slide-verify"; //滑块验证
import * as echarts from "echarts";
import md5 from "js-md5";

import login from "@/apis/login.js";
import queryData from "./apis/queryData.js";
import gcgk from "./apis/gcgk.js";
import xmtz from "./apis/xmtz.js";
import summary from "./apis/summary.js";
import company from "./apis/companyInfo.js";
import role from "./apis/role.js";
import hardware from "./apis/hardware.js";
import measured from "./apis/measured.js";
import task from "./apis/task.js";
import itemsCheck from "./apis/itemsCheck.js";
import changeCompany from "./apis/changeCompany.js";
import hot from "./apis/hot.js";
import message from "./apis/message.js";
import companyRoute from "./apis/companyRoute.js";
import process from "./apis/process.js";
import samping from "./apis/samping.js";
import VueClipboard from 'vue-clipboard2'



// vue全局监听本地存储
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === "changeData") {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};

// 引入高德地图
import VueAMap from "vue-amap";

Vue.use(VueAMap);


Vue.directive('action', {
  inserted: function (el, binding, vnode) {
    const actionName = binding.arg

    if (JSON.parse(sessionStorage.getItem("menusActions"))) {
      const menusActions = JSON.parse(sessionStorage.getItem("menusActions"))
      const actionList = []
      menusActions.forEach((item, index) => {
        actionList[index] = item.permissionId
      })
      if (!actionList.includes(actionName)) {
        // el.parentNode && el.parentNode.removeChild(el) || (el.style.display = 'none')
        el.style.visibility = 'hidden'
      }
    }
  }
})

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  // 高德的key
  key: "730c2ac906d42c00e4ed1938b14ccadf",
  // 插件集合
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor", //编辑 折线多，边形
    "AMap.CircleEditor", //圆形编辑器插件
    "AMap.Geolocation", //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  // 高德 sdk 版本，默认为 1.4.4
  v: "1.4.4",
});

Vue.prototype.$md5 = md5;
Vue.prototype.$echarts = echarts;
Vue.prototype.$login = login;
Vue.prototype.$queryData = queryData;
Vue.prototype.$gcgk = gcgk;
Vue.prototype.$xmtz = xmtz;
Vue.prototype.$summary = summary;
Vue.prototype.$company = company;
Vue.prototype.$role = role;
Vue.prototype.$hardware = hardware;
Vue.prototype.$measured = measured;
Vue.prototype.$task = task;
Vue.prototype.$itemsCheck = itemsCheck;
Vue.prototype.$changeCompany = changeCompany;
Vue.prototype.$hot = hot;
Vue.prototype.$messageTwo = message;
Vue.prototype.$companyRoute = companyRoute;
Vue.prototype.$process = process;
Vue.prototype.$samping = samping;

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueClipboard);
// Vue.use(Antd);
// Vue.prototype.$message = Message;
// Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$antdconfirm = Vue.prototype.$confirm;
Vue.use(SlideVerify);
Vue.use(axios);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
