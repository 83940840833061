import request from "@/utils/request";

export default {
  getMenu(params) {
    return request.post("/scsl/coopSysMenu/getMenu", params); //菜单数据
  },
  getStatistics(params) {
    return request.post("/scsl/xmGcgk/getStatistics", params); //首页上方数据
  },
  getStatisticsDay(params) {
    return request.post("/scsl/xmGcgk/getStatisticsDay", params); //最近检测统计
  },
  getStatisticsSubItem(params) {
    return request.post("/scsl/xmGcgk/GetStatisticsSubItem", params); //分项检测统计
  },
  GetStatisticsSecondBrand(params) {
    return request.post("/scsl/xmGcgk/GetStatisticsSecondBrand", params); //子分部检测统计
  },
  getFbTj(params) {
    return request.post("/scsl/xmGcgk/getFbTj", params); //分部检测统计
  },
  getInfo(params) {
    return request.get("/scsl/user/getInfo", params); //获取用户数据、权限
  },
  getJwd(params) {
    return request.post("/scsl/xmMap/getJwd", params); //查找图纸
  },
  // fbFxTree(params) {
  //   return request.post("/scsl/baseBranchSubitem/fbFxTree", params); //分部分项工程三级联动
  // },
  // selectByFxId(params){
  //   return request.post("/scsl/bussInspectionlot/selectByFxId", params);
  // },
  threeFbFxTree(params) {
    return request.post("/scsl/baseBranchSubitem/threeFbFxTree", params); //分部分项工程三级联动
  },
  selectByFxCode(params) {
    return request.post("/scsl/level/selectByFxCode", params); //根据分项id获取检验批
  },
  selectByJyp(params) {
    return request.post("/scsl/level/selectByJyp", params); //根据检验批id获取检测项
  },
  getNoByJyp(params) {
    return request.post("/scsl/level/getNoByJyp", params); //检测任务-选择检验批时调用获取数据
  },
  selectByInspectionlot(params) {
    return request.post(
      "/scsl/bussInspectionlotTask/selectByInspectionlot",
      params
    ); //根据检验批id获取检测项
  },
  dwgcFloorByXm(params) {
    return request.post("/scsl/dwGcgk/dwgcFloorByXm", params); //根据检验批id获取检测项
  },
  getUserID(params) {
    return request.post("/scsl/user/getUserID", params); //查询用户基本信息
  },
  UpdateUser(params) {
    return request.post("/scsl/user/UpdateUser", params); //修改用户基本信息
  },
  UserCheck(params) {
    return request.post("/UserCheck", params); //验证码校验
  },
  UpdateUserphone(params) {
    return request.post("/UpdateUserphone", params); //修改用户基本信息
  },
  UpdateUserPassword(params){
    return request.post("/scsl/user/UpdateUserPassword", params); //修改用户密码
  }
};
