import router from "./src/router";
import { multiportUrl } from "@/utils/request";

// 做一个全局守卫 防止登录页和首页通过路径来回切换 因为是全局的所以文件放在全局中
router.beforeEach((to, from, next) => {
    console.log(window)
    const pattern = window.baseconfig.pattern
    const isToken = sessionStorage.getItem("token");
  setTimeout(() => {
    if (to.path === '/redirectUrl') {
      next();
    } else {
      // 有token  去登录页，不让去。去的不是登录页，放行
      if (isToken) {
        // 有权限  那些路由都能去，没权限重定向到首页
        if (to.path === "/") {
          next();  //"/home/homePage"
        } else {
          next();
        }
      } else {
        if (pattern) {
          if (to.path === "/") {
            next();
          } else {
            next({
              path: "/",
            });
          }
        } else {
            // if(to.path===`${multiportUrl}?logout=1`){
          window.location.href = multiportUrl;
        }
        // 没有token  去登录页，放行。去的不是登录页，让你去登录页
      }
    }
  }, 50);
});

// 记得将这个文件引入man.js中
