<template>
  <div class="w-full h-full loginPage">
    <div class="content">
      <!-- <div class="selfHeader">
        <div class="title">
          <img src="@/assets/imgs/hmyl-private-deploy-logo.png" alt="" />
          <span>为质量追求者服务</span>
        </div>
        <p class="english">GIVE SERVICE TO QUALITY PURSUER</p>
      </div> -->
      <div class="cyLogo">
        <img src="@/assets/imgs/cyLogo.svg" alt="" />
      </div>
      <div class="erCode"  style="width: 64px;height: 64px;">
        <img class="erCode"
        src="@/assets/imgs/erwm.svg"
        alt=""/>
      </div>
      <div class="logins">
        <!-- 右边主体框 -->
        <div class="main">
          <div class="top">
            <div class="header">
              <p class="text-left" style="margin-bottom: 40px">{{ title }}</p>
            </div>
          </div>
          <!-- tab切换 -->
          <div v-if="operaType == 1">
            <div class="tabs-header">
              <div class="flex items-center justify-around">
                <div
                  class="cursor-pointer passLogin"
                  :class="{ 'tabs-active': activeName == 'first' }"
                  @click="switchlogin('first')"
                >
                  密码登录
                </div>
                <div
                  class="cursor-pointer"
                  :class="{ 'tabs-active': activeName == 'second' }"
                  @click="switchlogin('second')"
                >
                  短信登录
                </div>
              </div>
            </div>
            <!-- 密码登录 -->
            <el-form
              ref="passwordForm"
              :model="passwordForm"
              class="forms"
              :rules="passwordRules"
              v-if="activeName == 'first'"
            >
              <!--   @blur="handleEmailRealCheck" -->
              <el-form-item prop="phone">
                <el-input
                  v-model="passwordForm.phone"
                  placeholder="请输入手机号"
                  class="form-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  :type="passT"
                  v-model="passwordForm.password"
                  placeholder="请输入密码（6～20个字符）"
                  class="form-input"
                ></el-input>
                <img class="email-eye" :src="eyeImg" @click="changeView" />
              </el-form-item>
              <el-form-item prop="code">
                <div class="yzm">
                  <div class="fl yzm-left">
                    <!-- @keyup.enter.native="submitForm('userForm')" -->
                    <el-input
                      v-model.number="passwordForm.code"
                      placeholder="请输入验证码"
                      class="user-input bg-white"
                      style="border-radius: 6px"
                    ></el-input>
                  </div>
                  <div class="fr yzm-right">
                    <div class="yzm-warp">
                      <span class="authCode" slot="suffix">{{
                        num1 + "+" + num2 + "=?"
                      }}</span>
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
                <!-- <el-input v-model="passwordForm.code" placeholder="请输入验证码" class="form-input"></el-input>
                <div class="math absolute" id="verCode"></div> -->
              </el-form-item>
            </el-form>
            <!-- 短信登录 -->
            <el-form
              ref="noteForm"
              :model="noteForm"
              :rules="noteFormRules"
              class="forms"
              v-if="activeName == 'second'"
            >
              <el-form-item prop="username">
                <el-input
                  v-model="noteForm.username"
                  placeholder="请输入手机号"
                  class="form-input"
                ></el-input>
              </el-form-item>
              <el-form-item prop="phoneCode">
                <div class="yzm2">
                  <div class="fl yzm-left">
                    <el-input
                      v-model="noteForm.phoneCode"
                      placeholder="请输入验证码"
                      class="form-input"
                    ></el-input>
                  </div>
                  <div class="fr yzm-right">
                    <div class="yzm-warp cursor-pointer" @click="getCode">
                      {{ authTxt }}
                    </div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
            <div
              class="link-footer w-full flex justify-between items-center"
              style="display: flex; justify-content: space-between"
            >
              <!-- <div class="link-desc cursor-pointer" @click="closeRegisterOpen">
                注册账号
              </div> -->
              <div
                class="link-desc cursor-pointer"
                style="color: #0084ff; text-align: right"
                @click="closeForgetOpen"
              >
                忘记密码？
              </div>
            </div>
            <el-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              @click="newLogin"
              :loading="CLloginState"
              >登录
            </el-button>
          </div>
          <!-- 用户注册 -->
          <div v-else-if="operaType == 2">
            <div v-if="!registerState">
              <el-form
                :model="registerForm"
                :rules="registerRules"
                ref="registerForm"
                class="demo-userForm"
              >
                <el-form-item prop="name">
                  <div class="flex aa">
                    <div style="width: 73%">
                      <el-input
                        v-model="registerForm.name"
                        placeholder="请输入姓名"
                        class="userss-input userss-left-input"
                        @input="confirmRealname"
                      >
                      </el-input>
                    </div>
                    <div style="width: 27%">
                      <el-select
                        placeholder="选择性别"
                        v-model="registerForm.gender"
                        class="userss-input userss-right-input w-full ccc"
                      >
                        <el-option label="男" :value="0"></el-option>
                        <el-option label="女" :value="1"></el-option>
                      </el-select>
                    </div>
                  </div>
                </el-form-item>

                <el-form-item prop="zcPhone">
                  <div class="flex justify-between">
                    <div class="relative" style="width: 70%">
                      <el-input
                        v-model="registerForm.zcPhone"
                        placeholder="请输入电话"
                        class="user-input userss-left-input"
                        maxlength="11"
                      >
                        <!-- @input="confirmTelephone" -->
                      </el-input>
                      <div
                        class="codeBtn text-center absolute cursor-pointer"
                        @click="sendCode"
                      >
                        {{ countDownText }}
                      </div>
                    </div>
                    <div style="width: 27%">
                      <!-- <el-input v-model.number="registerForm.zcCode" placeholder="输入验证码" class="user-input"></el-input> -->
                      <el-input
                        v-model="registerForm.zcCode"
                        onkeyup="value=value.replace(/[^\d]/g,'')"
                        placeholder="输入验证码"
                        class="user-input"
                      ></el-input>
                    </div>
                  </div>
                </el-form-item>

                <!-- 在type="password"的input输入框上再加一个隐形的、不影响页面的input，防止上面的验证码输入框聚焦时出现浏览器自动填充账号密码 -->
                <el-form-item
                  prop="zcPassword"
                  style="opacity: 0; position: absolute"
                >
                  <el-input
                    v-model="registerForm.zcPassword999"
                    placeholder="输入密码"
                    class="user-input"
                  ></el-input>
                </el-form-item>
                <!-- 在type="password"的input输入框上再加一个隐形的、不影响页面的input，防止上面的验证码输入框聚焦时出现浏览器自动填充账号密码 -->

                <el-form-item prop="zcPassword">
                  <el-input
                    type="password"
                    v-model="registerForm.zcPassword"
                    placeholder="输入密码"
                    class="user-input ipt"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="zcPassword2">
                  <el-input
                    type="password"
                    v-model="registerForm.zcPassword2"
                    placeholder="确认密码"
                    class="user-input ipt"
                  >
                  </el-input>
                </el-form-item>
                <!-- <el-form-item prop="gender">
                  <el-select placeholder="选择性别" v-model="registerForm.gender" class="user-input w-full">
                    <el-option label="男" :value="0"></el-option>
                    <el-option label="女" :value="1"></el-option>
                  </el-select>
                </el-form-item> -->
                <div style="cursor: pointer" class="mb goLogin justify-end">
                  <span>已有账号？</span
                  ><span
                    style="color: #83c1ff; font-weight: bold; font-size: 16px"
                    @click="
                      operaType = 1;
                      title = '智能实测实量';
                      clearValidate('registerForm');
                      refreshVerCode();
                    "
                    >去登录</span
                  >
                </div>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="userRegister"
                    class="widthmax el-btn"
                    :loading="CLloginState"
                    >立即注册
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="text-center" v-else>
              <img src="@/assets/imgs/register-success-icon.svg" />
              <div class="success-wenzi">恭喜你，注册成功</div>
              <el-button
                size="large"
                type="primary"
                class="login-button"
                @click="loginRightNow(1)"
                :loading="CLloginState"
                >立即登录
              </el-button>
            </div>
          </div>
          <!-- 忘记密码 -->
          <div v-else-if="operaType == 3">
            <p
              class="title"
              style="font-size: 20px; text-align: left; margin-bottom: 0"
              v-if="operaType == 3"
            >
              {{ forgetTitleP2 }}
            </p>
            <div
              v-if="forgetStep == 1 || forgetStep == 2"
              style="margin-top: 80px"
            >
              <el-form
                :model="forgetPasswordForm"
                :rules="forgetRules"
                ref="forgetPasswordForm"
                class="demo-userForm"
                v-if="forgetStep == 1"
              >
                <el-form-item prop="forgetPhone">
                  <el-input
                    v-model="forgetPasswordForm.forgetPhone"
                    placeholder="请输入手机号"
                    class="user-input"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="forgetCode">
                  <div class="yzm">
                    <div class="fl yzm-left">
                      <el-input
                        v-model.number="forgetPasswordForm.forgetCode"
                        placeholder="请输入验证码"
                        class="user-input"
                      ></el-input>
                    </div>
                    <div class="fr yzm-right">
                      <div class="yzm-warp-btn">
                        <span
                          class="authCodeBtn"
                          slot="suffix"
                          @click="sendForgetCode"
                          >{{ forgetCountDownText }}</span
                        >
                      </div>
                    </div>
                    <div class="clear"></div>
                  </div>
                </el-form-item>
                <div
                  class="text-right cursor-pointer"
                  style="color: #0084ff"
                  @click="
                    operaType = 1;
                    forgetStep = 1;
                    title = '智能实测实量';
                    clearValidate('forgetPasswordForm');
                    refreshVerCode();
                    forgetTitleP2 = '手机验证';
                  "
                >
                  返回登录
                </div>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="forgetNextFuc()"
                    class="widthmax login-btn el-btn"
                    style="margin-top: 21px"
                    >下一步
                  </el-button>
                </el-form-item>
              </el-form>
              <el-form
                :model="forgetPasswordForm"
                :rules="forgetRules"
                ref="forgetPasswordForm"
                class="demo-userForm"
                v-else-if="forgetStep == 2"
              >
                <el-form-item prop="newPassword">
                  <el-input
                    type="password"
                    v-model="forgetPasswordForm.newPassword"
                    placeholder="请输入新密码"
                    class="user-input"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item prop="newPassword2">
                  <el-input
                    type="password"
                    v-model="forgetPasswordForm.newPassword2"
                    placeholder="请再次输入新密码"
                    class="user-input"
                  >
                  </el-input>
                </el-form-item>
                <div
                  class="text-right cursor-pointer"
                  style="color: #0084ff; text-align: right"
                  @click="
                    operaType = 1;
                    forgetStep = 1;
                    title = '智能实测实量';
                    clearValidate('forgetPasswordForm');
                    forgetTitleP2 = '手机验证';
                  "
                >
                  返回登录
                </div>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="forgetLogin"
                    class="widthmax login-btn el-btn"
                    style="margin-top: 21px"
                    :loading="CLloginState"
                    >确定
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="text-center" v-else>
              <img src="@/assets/imgs/register-success-icon.svg" />
              <div class="success-wenzi">密码重置成功！</div>
              <el-button
                type="primary"
                @click="loginRightNow(2)"
                class="widthmax el-btn"
                :loading="CLloginState"
                >立即登录
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-layout-foot">
      <div class="foot">
        <span>www.abuildingcloud.com</span>
      </div>
      <p class="internet-content-crovider">
        Copyright ©版权所有 豪米优量科技 浙ICP备202209088号
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const regTel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; // 11位手机号正则验证
    const regEml =
      /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/; //邮箱地址
    const regPass = /^[a-zA-Z0-9.]{6,20}$/;
    const handlePhoneChange = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        // if (!regTel.test(value) && !regEml.test(value)) {
        //   callback(new Error("请输入正确的手机号或邮箱"));
        // } else {
        this.handlePhoneCheck(rule, value, callback);
        // }
      }
    };
    const checkPhone = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入手机号"));
      } else if (!regTel.test(value)) {
        callback(new Error("请输入正确的11位手机号"));
      } else {
        this.$login
          .checkPhone({
            phone: value,
          })
          .then((res) => {
            if (res.code == 200) {
              callback();
            } else {
              callback(new Error("手机号不存在,请先注册!"));
            }
          })
          .catch((err) => {
            callback(new Error(err.msg));
          });
      }
    };
    const handleEmailChange = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!regTel.test(value) && !regEml.test(value)) {
          callback(new Error("请输入正确的手机号或邮箱"));
        } else {
          this.handleEmailCheck(rule, value, callback);
        }
      }
    };
    const handleZcPasswordChange = (rule, value, callback) => {
      const password = this.registerForm.zcPassword;
      if (value != password) {
        callback(new Error("请保持密码一致"));
      } else {
        if (!regPass.test(value)) {
          callback(new Error("请输入6到20个字符"));
        } else {
          callback();
        }
      }
    };
    return {
      uuId: "",
      mark: 1,
      pcOrPhone: true,
      checked: false,
      tooltipShow: false,
      avatar: "",
      nickname: "",
      operaType: 1, //操作类型，1-登录，2-注册，3-忘记密码
      registerState: false,
      forgetStep: 1,
      title: "智能实测实量",
      activeName: "first",
      passwordForm: {
        phone: "",
        password: "",
        code: "",
      },
      noteForm: {
        username: "",
        phoneCode: "",
      },
      registerForm: {
        name: "",
        gender: undefined,
        zcPhone: "",
        zcCode: "",
        zcPassword999: "",
        zcPassword: "",
        zcPassword2: "",
      },
      forgetPasswordForm: {
        // forgetPhone: sessionStorage.getItem("cookieloginPhone") || "",
        forgetPhone: "",
        newPassword: "",
        newPassword2: "",
        forgetCode: "",
        md5Code: "",
      },
      passwordRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      noteFormRules: {
        username: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        phoneCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      registerRules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        zcPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: handlePhoneChange, trigger: "blur" },
        ],
        zcCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        zcPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9.]{6,20}$/,
            message: "请输入6到20个字符",
            trigger: "blur",
          },
        ],
        zcPassword2: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: handleZcPasswordChange, trigger: "blur" },
        ],
        gender: [
          {
            required: true,
            message: "请选择性别",
            trigger: "blur",
            type: "number",
          },
        ],
      },
      forgetRules: {
        forgetPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        forgetCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            pattern: /^\d{6}$/,
            message: "请输入六位验证码",
            trigger: "blur",
          },
        ],
        newPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9.]{6,20}$/,
            message: "请输入6到20个字符",
            trigger: "blur",
          },
        ],
        newPassword2: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
      },
      authTxt: "发送验证码",
      countDownText: "发送验证码",
      forgetCountDownText: "发送验证码",
      passT: "password",
      eyeImg: require("@/assets/imgs/eye-invisible.svg"),
      iconEyeView: require("@/assets/imgs/eye.svg"),
      iconEyeClose: require("@/assets/imgs/eye-invisible.svg"),
      errorMsgEmail: "",
      countDownNumber: 60,
      timer: null,
      CLloginState: false,
      forgetTitleP2: "手机验证",
      phoneCode: "",
      loginUUId: "",
      menusActions: [],
    };
  },

  computed: {
    num1() {
      return Math.floor(Math.random() * 10);
    },
    num2() {
      return Math.floor(Math.random() * 10);
    },
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
  methods: {
    loginCheckPhone(val) {
      this.$login
        .checkPhone({
          phone: val,
        })
        .then((res) => {})
        .catch((err) => {
          if (err.code != 200) {
            callback(new Error(err.msg));
          }
        });
    },
    changeView() {
      if (this.passT == "password") {
        this.eyeImg = this.iconEyeView;
        this.passT = "text";
      } else {
        this.eyeImg = this.iconEyeClose;
        this.passT = "password";
      }
    },
    refreshVerCode() {
      setTimeout(() => {
        this.GetVerCode();
      }, 100);
    },
    GetVerCode() {
      this.vNum1 = Math.floor(Math.random() * 10);
      this.vNum2 = Math.floor(Math.random() * 10);
      this.vStr = `${this.vNum1}+${this.vNum2}=?`;
      //   const dom = document.getElementById("verCode");
      //   dom.innerHTML = ""; // 清除子节点
      //   const can = document.createElement("canvas");
      //   can.className = "code";
      //   can.addEventListener("click", () => {
      //     this.GetVerCode();
      //   });
      //   const ctx = can.getContext("2d");
      //   ctx.textAlign = "start";
      //   ctx.fillStyle = "#0D77E4";
      //   ctx.beginPath();
      //   ctx.font = "calc(100 / 1920 * 85vw) 微软雅黑";
      //   ctx.fillText(this.vStr, 20, 105);
      //   dom.appendChild(can);
      //   can.style.height = "1.98vw";
    },
    switchlogin(type) {
      // this.loginType = !this.loginType
      if (type == "second") this.$refs.passwordForm.resetFields();
      else if (type == "first") this.$refs.noteForm.resetFields();
      this.activeName = type;
      if (this.activeName == "first") {
        setTimeout(() => {
          this.GetVerCode();
        }, 100);
      }
    },
    // 短信登录获取验证码
    getCode() {
      if (this.isDisable) {
        return false;
      }
      this.$refs.noteForm.validateField("username", (error) => {
        if (!error) {
          // const req = {
          //   mobile: values.mobile,
          //   type: 2,
          //   noload: true
          // }
          const that = this;
          // const { SendSms } = that
          // SendSms(req).then(res => {
          //   if (res.status === 1) {
          //     that.verCode = res.data.verCode
          //     that.setAuthTxt()
          //     that.$message({
          //       type: 'success',
          //       message: '验证码发送成功!'
          //     })
          //   } else {
          //     that.$message.error(res.msg)
          //   }
          // })
          const phone = this.noteForm.username;
          //   gets("/User/sendLoginVerifyCode", { phone }).then((res) => {
          //     if (res.code == 200) {
          //       // this.md5code = res.md5code;
          //       that.setAuthTxt();
          //       that.$message({
          //         type: "success",
          //         message: "验证码发送成功!",
          //       });
          //     } else {
          //       this.$message.error(res.msg);
          //     }
          //   });
          // this.$login
          //   .phoneLogin({
          //     phone: phone,
          //   })
          //   .then((res) => {
          //     if (res.code == 200) {
          //       this.$message.success("发送成功");
          //       that.setAuthTxt();
          //       // uuid
          //       this.phoneCode = res.msg;
          //     }
          //   })
          //   .catch((err) => {
          //   });
          this.$login
            .sendRegisterCode({
              phone: phone,
            })
            .then((res) => {
              if (res.code == 200) {
                this.setAuthTxt();
                this.loginUUId = res.data.uuid;
                this.$message.success("验证码已发送,请输入验证码");
              } else if (res.code == 500) {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {});
        }
      });
    },
    setAuthTxt() {
      let index = 60;
      this.isDisable = true;
      const that = this;
      this.authTxt = "60s后再试";
      setInterval(() => {
        index--;
        if (index < 0) {
          that.isDisable = false;
          that.authTxt = "发送验证码";
        } else {
          that.authTxt = `${index}s后再试`;
        }
      }, 1000);
    },
    closeRegisterOpen() {
      this.title = "欢迎注册";
      this.operaType = 2;
    },
    closeForgetOpen() {
      this.title = "忘记密码";
      this.operaType = 3;
    },
    newLogin() {
      // 密码登录
      let that = this;
      that.CLloginState = true;
      if (that.activeName == "first") {
        that.$refs.passwordForm.validate((valid) => {
          if (valid) {
            if (that.passwordForm.code !== that.num1 + that.num2) {
              that.$message.error("验证码错误！");
              that.CLloginState = false;
              return;
            }
            const formData = new FormData();
            formData.append("username", that.passwordForm.phone);
            formData.append("password", that.passwordForm.password);
            that.$login.login(formData).then((res) => {
              if (res.code == 200) {
                sessionStorage.setItem("token", res.token);
                sessionStorage.setItem("routeMsg", false);
                setTimeout(() => {
                  that.CLloginState = false;
                  that.queryBasicInfo();
                  // this.$router.push({
                  //   path: "/home",
                  // });
                  // this.$message.success("登录成功");
                }, 1000);
              } else {
                setTimeout(() => {
                  that.CLloginState = false;
                  that.$message.error("密码错误，请输入正确密码");
                }, 1000);
              }
            });
          } else {
            setTimeout(() => {
              that.CLloginState = false;
            }, 1000);
          }
        });
      } else if (that.activeName == "second") {
        // 短信登录
        that.$refs.noteForm.validate((valid) => {
          if (valid) {
            // const query = {};
            // query.phone = that.noteForm.username;
            // query.code = that.noteForm.phoneCode;
            // posts("/User/sdphoneCodeLogin", query).then((res) => {
            //   if (res.code == 200) {
            //   } else {
            //     that.CLloginState = false;
            //     that.$message.error(res.msg);
            //   }
            // });
            // let result = this.$md5(that.noteForm.phoneCode) == this.phoneCode;
            // 判断验证码正确再发送接口登录
            if (that.loginUUId) {
              const formdata = new FormData();
              formdata.append("phone", that.noteForm.username);
              formdata.append("code", that.noteForm.phoneCode);
              formdata.append("uuid", that.loginUUId);
              that.$login
                .noteLogin(formdata)
                .then((res) => {
                  if (res.code == 200) {
                    sessionStorage.setItem("token", res.token);
                    sessionStorage.setItem("routeMsg", false);
                    that.$message.success("登录成功");
                    that.CLloginState = false;
                    setTimeout(() => {
                      that.CLloginState = false;
                      that.queryBasicInfo();
                      // this.$router.push({
                      //   path: "/home",
                      // });
                    }, 1000);
                  } else {
                    that.$message.error(res.msg);
                    that.CLloginState = false;
                  }
                })
                .catch((err) => {
                  that.$message.error(res.msg);
                  setTimeout(() => {
                    that.CLloginState = true;
                  }, 1500);
                });
            } else {
              that.$message.error("验证码错误");
              setTimeout(() => {
                that.CLloginState = false;
              }, 1500);
            }
          }
        });
      }
    },
    queryBasicInfo() {
      this.$queryData.getInfo().then((res) => {
        if (res.code == 200 && res.companyInfo) {
          if (res.menus && res.menus.length != 0) {
            let currentPath = "";
            currentPath = res.menus[0].path
              ? res.menus[0].path
              : res.menus[0].children[0].path;
            this.$router.push({
              path: currentPath,
            });
            this.filterAsyncRouter(res.menus);
          } else {
            this.$router.push("/home/homepage");
          }
          sessionStorage.setItem("user", JSON.stringify(res.user));
          sessionStorage.setItem("accountID", res.user.id);
          sessionStorage.setItem("currentUser", JSON.stringify(res.user));
          sessionStorage.setItem(
            "companyInfo",
            JSON.stringify(res.companyInfo)
          );
          sessionStorage.setItem("companyId", res.companyInfo.id);
          setTimeout(() => {
            sessionStorage.setItem(
              "menusActions",
              JSON.stringify(this.menusActions)
            );
          }, 500);
        } else if (res.code == 401) {
          this.$message.error("登录过期，请重新登录");
          sessionStorage.removeItem("token");
          this.$router.push({
            path: "/",
          });
        } else {
          // 没公司信息，去创建加入公司
          sessionStorage.setItem("user", JSON.stringify(res.user));
          sessionStorage.setItem("accountID", res.user.id);
          sessionStorage.setItem("currentUser", JSON.stringify(res.user));
          this.firstStatus = 0;
          sessionStorage.setItem("status", 0);
          this.$router.push({
            path: "/companyRoute",
          });
        }
      });
    },
    filterAsyncRouter(arr) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].menuType == "F")
          this.menusActions = [...this.menusActions, arr[i]];
        else if (arr[i].children !== undefined && arr[i].children.length > 0)
          this.filterAsyncRouter(arr[i].children);
      }
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.newLogin(); // 定义的登录方法
      }
    },
    setCookie() {
      if (this.autoCheck) {
        const Base64 = require("js-base64").Base64;
        const session = Base64.encode(
          this.passwordForm.phone + "," + this.passwordForm.password
        );
        this.$cookies.set("U_SESSION", session, "7d");
      }
    },
    // 发送验证码
    sendCode() {
      let phone = this.registerForm.zcPhone;
      this.$login
        .sendRegisterCode({
          phone: phone,
        })
        .then((res) => {
          if (res.code == 200) {
            this.countDownFuc();
            this.timer = setInterval(this.countDownFuc, 1000);
            this.uuId = res.data.uuid;
            this.$message.success("验证码已发送,请输入验证码");
          } else if (res.code == 500) {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {});
      //   this.$refs.registerForm.validateField((value)=>{
      //   })
      //   this.$refs.registerForm.validateField("zcPhone", (error) => {
      //     if (!error) {
      //       if (this.countDownText == "发送验证码") {
      //         let phone = this.registerForm.zcPhone;
      //         // gets("/User/sendVerifyCode", { phone }).then((res) => {
      //         //   if (res.code == 200) {
      //         //     // this.md5code = res.md5code;
      //         //     this.$message.success("验证码发送成功！！");
      //         //     this.countDownFuc();
      //         //     this.timer = setInterval(this.countDownFuc, 1000);
      //         //   } else {
      //         //     this.$message.error(res.message);
      //         //   }
      //         // });

      //       }
      //     }
      //   });
    },
    // 开始倒计时
    countDownFuc() {
      if (this.countDownNumber > 0) {
        this.countDownNumber--;
        this.countDownText = this.countDownNumber + "s后重新发送";
      } else {
        clearInterval(this.timer);
        this.countDownText = "发送验证码";
        this.countDownNumber = 60;
      }
    },
    // 忘记密码开始倒计时
    forgetCountDown() {
      if (this.forgetCountDownNumber > 0) {
        this.forgetCountDownNumber--;
        this.forgetCountDownText = this.forgetCountDownNumber + "s后重新发送";
      } else {
        clearInterval(this.timer);
        this.forgetCountDownText = "发送验证码";
        this.forgetCountDownNumber = 60;
      }
    },
    // 发送忘记密码验证码
    sendForgetCode() {
      const that = this;
      that.$refs.forgetPasswordForm.validateField("forgetPhone", (error) => {
        if (!error) {
          if (that.forgetCountDownText == "发送验证码") {
            let phone = that.forgetPasswordForm.forgetPhone;
            that.$login
              .resetPWCode({
                phone: phone,
              })
              .then((res) => {
                if (res.code == 200) {
                  that.forgetCountDown();
                  that.timer = setInterval(that.forgetCountDown, 1000);
                  that.uuId = res.data.uuid;
                  that.$message.success("验证码已发送,请输入验证码");
                } else if (res.code == 500) {
                  that.$message.error(res.msg);
                }
              })
              .catch((err) => {});
            // gets("/User/sendLoginVerifyCode", { phone }).then((res) => {
            //   if (res.code == 200) {
            //     this.$message.success(res.msg);
            //     this.forgetPasswordForm.md5Code = res.md5code;
            //     this.forgetCountDown();
            //     this.timer = setInterval(this.forgetCountDown, 1000);
            //   } else {
            //     this.$message.error(res.msg);
            //   }
            // });
          }
        }
      });
    },
    clearValidate(ref) {
      this.$refs[ref].clearValidate();
    },
    // 新的用户注册
    userRegister() {
      const that = this;
      this.$login
        .register({
          code: this.registerForm.zcCode,
          gender: 0,
          password: this.registerForm.zcPassword,
          phone: this.registerForm.zcPhone,
          realName: this.registerForm.name,
          type: 0,
          username: this.registerForm.name,
          uuid: this.uuId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("注册成功");
            this.title = "智能实测实量";
            this.operaType = 1;
            // this.switchlogin("first");
          } else if (res.code == 500) {
            this.$message.error(res.msg);
          }
          that.CLloginState = false;
        })
        .catch((err) => {
          this.$message.error(err.msg);
          that.CLloginState = false;
        });
      //   this.$refs.registerForm.validate((success) => {
      //     const that = this;
      //     if (success) {
      //       that.CLloginState = true;
      //       const query = {};
      //       query.realname = that.registerForm.name;
      //       query.username = that.registerForm.zcPhone;
      //       query.phone = that.registerForm.zcPhone;
      //       query.code = that.registerForm.zcCode;
      //       query.password = that.registerForm.zcPassword;
      //       query.gender = that.registerForm.gender;
      //       query.type = 1;

      //       //   posts("/User/register", query)
      //       //     .then((res) => {
      //       //       if (res.code == 200) {
      //       //         that.registerState = true;
      //       //         setTimeout(() => {
      //       //           that.CLloginState = false;
      //       //         }, 1000);
      //       //       } else {
      //       //         that.$message({
      //       //           type: "error",
      //       //           message: res.msg,
      //       //         });
      //       //         that.CLloginState = false;
      //       //       }
      //       //     })
      //       //     .catch(() => {
      //       //       that.CLloginState = false;
      //       //     });
      //     }
      //   });
    },
    forgetNextFuc() {
      var that = this;
      that.forgetTitleP2 = "密码重置";
      that.forgetStep = 2;
    },
    // 修改密码
    forgetLogin() {
      this.CLloginState = true;
      this.$login
        .restPassword({
          phone: this.forgetPasswordForm.forgetPhone,
          code: this.forgetPasswordForm.forgetCode + "",
          newPassword: this.forgetPasswordForm.newPassword,
          affirmPassword: this.forgetPasswordForm.newPassword2,
          uuid: this.uuId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
          }
          this.CLloginState = false;
        })
        .catch((err) => {
          this.$message.error(res.msg);
          this.CLloginState = false;
        });
      //   posts("/User/restPassword", {
      //     phone: this.forgetPasswordForm.forgetPhone,
      //     code: this.forgetPasswordForm.forgetCode,
      //     newPassword: this.forgetPasswordForm.newPassword,
      //     affirmPassword: this.forgetPasswordForm.newPassword2,
      //   }).then((res) => {
      //     if (res.code == 1) {
      //       this.forgetStep = 3;
      //       this.$message.success(res.msg);
      //       setTimeout(() => {
      //         this.CLloginState = false;
      //       }, 1000);
      //     } else {
      //       this.$message.error(res.msg);
      //       this.CLloginState = false;
      //     }
      //   });
    },
    // handleEmailRealCheck(e) {
    //   let regTel =
    //     /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    //   if (regTel.test(e)) {
    //     posts("/User/checkPhone", { phone: e }).then((res) => {
    //       if (res.code == 0) {
    //         this.errorMsgEmail = "该手机号尚未注册";
    //       }
    //     });
    //   }
    //   //   else {
    //   //     const regEml =
    //   //       /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/;
    //   //     const isQQemail = e.includes("@qq");
    //   //     const arr = e.split(".");
    //   //     if (regEml.test(e)) {
    //   //       if (isQQemail) {
    //   //         if (arr[1].length == 3) {
    //   //           posts("/User/checkEmail", { email: e }).then((res) => {
    //   //             if (res.code == 0) {
    //   //               this.errorMsgEmail = "该邮箱尚未注册";
    //   //             }
    //   //           });
    //   //         }
    //   //       } else {
    //   //         posts("/User/checkEmail", { email: e }).then((res) => {
    //   //           if (res.code == 0) {
    //   //             this.errorMsgEmail = "该邮箱尚未注册";
    //   //           }
    //   //         });
    //   //       }
    //   //     }
    //   //   }
    // },
    // 正则检验过后检验该手机号是否注册，未注册的通过
    handlePhoneCheck(rule, value, callback) {
      //   this.$login
      //     .sendRegisterCode({
      //       phone: this.registerForm.zcPhone,
      //     })
      //     .then((res) => {
      //       if (res.code == 200) {
      //         this.uuId = res.data.uuid;
      //         callback();
      //       }
      //     })
      //     .catch((err) => {
      //       callback(new Error(res.msg));
      //     });
      //   posts("/User/checkPhone", { phone: value }).then((res) => {
      //     if (res.code == 0) {
      //       callback();
      //     } else {
      //       callback(new Error(res.msg));
      //     }
      //   });
    },
    yzl() {
      window.open("https://www.abuildingcloud.com", "_blank");
    },
    checkedChange(val) {
      this.checked = val;
    },
    checkedHint() {
      this.tooltipShow = false;
      this.tooltipShow = true;
    },
    emitEnter() {
      this.mark = 2;
    },
    emitEnroll() {
      this.mark = 1;
    },
    emitForget() {
      this.mark = 1;
    },
    wj() {
      this.mark = 3;
    },
    login() {
      this.$refs.ent.login();
    },
    bindBack() {
      this.mark = 1;
    },
    loginRightNow(type) {
      if (type == 1) {
        this.operaType = 1;
        this.activeName = "first";
        this.registerState = false;
        this.lijiLogin(this.registerForm.zcPhone, this.registerForm.zcPassword);
      } else {
        this.loginState = 0;
        this.forgetStep = 1;
        this.operaType = 1;
        this.activeName = "first";
        this.title = "智能实测实量";
        this.lijiLogin(
          this.forgetPasswordForm.forgetPhone,
          this.forgetPasswordForm.newPassword
        );
      }
    },
    lijiLogin(username, password) {
      let that = this;
      that.CLloginState = true;
      const formData = new FormData();
      formData.append("username", that.passwordForm.phone);
      formData.append("password", that.passwordForm.password);
      that.$login.login(formData).then((res) => {
        if (res.code == 200) {
          sessionStorage.setItem("token", res.token);
          sessionStorage.setItem("routeMsg", false);
          setTimeout(() => {
            that.CLloginState = false;
            that.queryBasicInfo();
          }, 1000);
        } else {
          setTimeout(() => {
            that.CLloginState = false;
            that.$notify.error({
              title: "登录失败",
              message: res.msg,
              duration: 2000,
            });
          }, 1000);
        }
      });
    },
    confirmTelephone() {
      if (/\D/g.test(this.registerForm.zcPhone)) this.registerForm.zcPhone = "";
    },
    confirmRealname() {
      if (/[^a-zA-Z\u4e00-\u9fa5]/g.test(this.registerForm.name)) {
        this.registerForm.name = this.registerForm.name.slice(
          0,
          this.registerForm.name.length - 1
        );
      }
    },
  },
};
</script>

<style lang="scss" src="@/assets/css/login2.scss" scoped></style>
