import request from "@/utils/request";
export default {
  // checkCompanyunique(params) {
  //   return request.post(
  //     `/loginInfo/myCreateandjoin?userid=${params.params.userid}`,
  //     params
  //   ); //查询企业数据
  // },
  getNotItem(params) {
    // 查询消息数量
    return request.get(
      `/loginInfo/getNotHandedOver?companyId=${params.companyId}`
    );
  },
  changeCompany(params) {
    return request.post(
      `/loginInfo/changeCompany?companyId=${params.companyId}&userid=${params.userid}`,
      params
    ); //切换企业
  },
  // 数据转移
  saveDevolvedAndQuit(params) {
    return request.post("/loginInfo/saveDevolvedAndQuit", params);
  },
  checkCompanyunique(params) {
    return request.post("/loginInfo/checkCompanyunique", params); //检查企业是否唯一
  },
  myCreateandjoin(params) {
    return request.get(
      `/loginInfo/myCreateandjoin?userid=${params.userid}&search=${params.search}`
    ); //切换企业，查询我创建我已加入的数据
  },
  //   /loginInfo/getCompayInfo
  getCompayInfo(params) {
    return request.get(
      `/loginInfo/getCompayInfo?companyId=${params.companyId}`,
      params.data
    ); //我创建和加入的公司--编辑公司接口
  },
  queryFiveBodyType(params) {
    return request.post("/loginInfo/queryFiveBodyType", params); //查询公司五方主体
  },
  //   /loginInfo/saveCompayInfo
  getDevolvedUsers(params) {
    return request.get(
      `/loginInfo/getDevolvedUsers?roleId=${params.roleId}&userId=${params.userId}`
    )
  },
  getDevolvedUsers2(params) {
    return request.get(
      `/loginInfo/getDevolvedUsers?roleId=${params.roleId}`
    )
  },
  quitCompany(params) {
    return request.post("/loginInfo/quitCompany", params);
  },

  uploadSealImage(params) {
    return request.post("/loginInfo/uploadSealImage", params); //图片上传
  },
  deleteFilePhoto(params) {
    return request.post("/loginInfo/deleteFilePhoto", params); //删除图片
  },
  initiateInvitation(params) {
    return request.get(
      `/loginInfo/initiateInvitation?companyId=${params.companyId}&inviterId=${params.inviterId}`
    );
  },
  saveCompany(params) {
    return request.post("/loginInfo/saveCompany", params); //创建新公司
  },
  saveCompayInfo(params) {
    return request.post("/loginInfo/saveCompayInfo", params); //修改公司
  },
  checkInvitationCode(params) {
    return request.get(
      `/loginInfo/checkInvitationCode?invitationCode=${params.invitationCode}`,
      params.data
    ); //检验邀请码是否存在
  },
  queryRolebycompany(params) {
    return request.get(
      `/loginInfo/queryRolebycompany?companyId=${params.companyId}`,
      params.data
    ); //加入企业-根据公司id查询所属角色(不包括admin)
  },
  //   /loginInfo/joinCompany
  joinCompany(params) {
    return request.post(`/loginInfo/joinCompany`, params); //加入企业接口
  },
  updatePassword(params) {
    return request.post(`/loginInfo/updatePassword`, params); //修改密码
  },
  devolvedInfoList(params) {
    return request.post(`/loginInfo/devolvedInfoList`, params); //消息列表
  },
  updateUser(params) {
    return request.post(`/loginInfo/updateUser`, params); //消息列表
  },
  getCompanyRoles(params) {
    return request.get(
      `/loginInfo/getCompanyRoles?companyId=${params.companyId}`
    );
  },
  saveUser(params) {
    return request.post(`/loginInfo/saveUser`, params);
  },
  updateUserStatus(params) {
    return request.post(`/loginInfo/updateUserStatus`, params);
  },
  checkPhone(params) {
    return request.post(`/loginInfo/checkPhone`, params);
  },
  queryUsers(params) {
    return request.post(`/loginInfo/queryUsers`, params);
  },
};
