import request from "@/utils/request";

export default {
  login(params) {
    return request.post("/login", params);
  },
  noteLogin(params) {
    return request.post("/noteLogin", params); //短信登录
  },
  phoneLogin(params) {
    return request.post("/scsl/dlPhoneYzm", params);
  },
  xzmLogin(params) {
    return request.post("/scsl/xzmLogin", params);
  },
  resetPWCode(params) {
    return request.get("/resetPWCode", params); //发送忘记密码短信验证码
  },
  register(params) {
    return request.post("/register", params); //用户注册接口
  },
  sendRegisterCode(params) {
    return request.get("/sendRegisterCode", params); //发送注册的短信验证码
  },
  restPassword(params) {
    return request.post("/restPassword", params); //登录忘记密码-重置密码
  },
  checkPhone(params) {
    return request.get("/checkPhone", params); //登录检查手机号是否存在
  },
};
