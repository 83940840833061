import Vue from "vue";
import Vuex from "vuex";
import defaultAvatar from '@/assets/imgs/shi.svg'
import jiansheAvatar from '@/assets/imgs/jianshe.svg'
import jianliAvatar from '@/assets/imgs/jianli.svg'
import sheAvatar from '@/assets/imgs/she.svg'
import kanAvatar from '@/assets/imgs/kan.svg'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    avatar: defaultAvatar,
    nickname: 'admin',
    branch: 0, //分部id
    subdivisionId: 0, //子分部id
    itemize: 0, //分项
    branchClick: 0, //分部点击
    subdivisionClick: 0, //分项点击
    branchList: [], //分部检测数据
    currentProject: 1, //当前项目
    currentCompanyInfo: {}, //当前公司信息
    show: true,
    checkTableData: [], //检测项表格数据
    addTaskStatus: false, //添加任务弹框状态
    nowCompanyId: 0, //当前公司id
    firstInterId: 0,//首次进入公司的id
    noItem: false,
  },
  getters: {},
  mutations: {
    SET_noItem(state, payload) {
      state.noItem = payload;
    },
    SET_firstInterId(state, payload) {
      state.firstInterId = payload;
    },
    SET_nowCompanyId(state, payload) {
      state.nowCompanyId = payload;
    },
    SET_addTaskStatus(state, payload) {
      state.addTaskStatus = payload;
    },
    SET_addTaskStatus(state, payload) {
      state.addTaskStatus = payload;
    },
    SET_checkTableData(state, payload) {
      state.checkTableData = payload;
    },
    SET_branch(state, payload) {
      state.branch = payload;
    },
    SET_currentProject(state, payload) {
      state.currentProject = payload;
    },
    SET_subdivisionId(state, payload) {
      state.subdivisionId = payload;
    },
    SET_show(state, payload) {
      state.show = payload;
    },
    SET_NAME(state, payload) {
      state.nickname = payload
    },
    SET_AVATAR(state, payload) {
      if (payload === '1') {
        state.avatar = defaultAvatar
      } else if (payload === '2') {
        state.avatar = jiansheAvatar
      } else if (payload === '3') {
        state.avatar = jianliAvatar
      } else if (payload === '4') {
        state.avatar = sheAvatar
      } else if (payload === '5') {
        state.avatar = kanAvatar
      } else if (payload === '6') {
        state.avatar = defaultAvatar
      } else {
        state.avatar = defaultAvatar
      }
    },
    SET_nowCompanyInfo(state, payload) {
      state.currentCompanyInfo = payload;
    },
  },
  actions: {},
  modules: {},
});
