import request from "@/utils/request";

export default {
  // locationDataList(params) {
  //   return request.post("/scsl/locationData/locationDataList", params); //实测数据-检验批检测分页
  // },
  // selectLocationData(params) {
  //   return request.post("/scsl/locationData/selectLocationData", params); //实测数据-查看
  // },
  locationDataList(params) {
    return request.post(
      `/scsl/locationData/locationDataList?pageNum=${params.params.pageNum}&pageSize=${params.params.pageSize}`,
      params.data
    ); //检验批检测分页
  },
  selectLocationData(params) {
    return request.post("/scsl/locationData/selectLocationData", params); //实测数据-查看
  },
  abarbeitung(params) {
    return request.post("/scsl/locationData/abarbeitung", params); //发起整改
  },
  cancelAbarbeitung(params) {
    return request.post("/scsl/dataAbarbeitung/cancelAbarbeitung", params); //取消整改
  },
  finishAbarbeitung(params) {
    return request.post("/scsl/dataAbarbeitung/finishAbarbeitung", params); //整改完成
  },
  explosionPointList(params) {
    return request.post("/scsl/locationData/explosionPointList", params); //生成二维码存放本地
  },
  collectList(params) {
    return request.post("/scsl/locationData/collectList", params); //数据汇总-查看分页
  },
  taskStatistics(params) {
    return request.post("/scsl/bussTask/taskStatistics", params); //数据汇总-上方统计
  },
  selectMetricInformation(params) {
    return request.post("/scsl/locationData/selectMetricInformation", params); //数据汇总-爆点列表-整改回复-查看信息
  },
  getTestItemMsg(params) {
    return request.post("/scsl/locationData/getTestItemMsg", params); //获取检验批表格中检测项的数据
  },
  getOriginalRecord(params) {
    return request.postFile("/scsl/locationData/getOriginalRecord", params); //获取原始记录以及数据
  },
  getJypByTask(params) {
    return request.post("/scsl/locationData/getJypByTask", params); //根据任务获取检验批表格
  },
  summarizationOfData(params) {
    return request.post("/scsl/baseOtherTask/summarizationOfData", params); //其他任务-数据汇总分页
  },
  getOtherTable(params) {
    return request.getFile(`/scsl/baseOtherTask/getOtherTable/${params.recordId}`); //数据汇总查看-获取其他表格及数据
  },
  getMetricalInformation(params) {
    return request.post("/scsl/baseOtherTask/getMetricalInformation", params); //数据汇总查看-测量信息(除混凝土回弹)
  },
  measuredDataList(params) {
    return request.post("/scsl/baseOtherTask/measuredDataList", params); //自定义检测-实测数据分页
  },
  otherTableDataPage(params) {
    return request.post("/scsl/baseOtherTask/otherTableDataPage", params); //自定义检测-查看数据检测页面(除回弹表的另外两张表)
  },
  getDataByTestItem(params) {
    return request.post("/scsl/baseOtherTask/getDataByTestItem", params); //自定义检测-查看数据-通过检测项获取数据
  },
  getMeasuredData(params) {
    return request.post("/scsl/baseOtherTask/getMeasuredData", params); //自定义检测-查看数据检测页面(回弹表)
  },
  getSpringBackInformation(params) {
    return request.post("/scsl/baseOtherTask/getSpringBackInformation", params); //数据汇总-自定义检测-查看-测量信息(混凝土回弹表测量信息)
  },
  getItemLocation(params) {
    return request.get(`/scsl/bussMeasurementLocations/getLocation/${params.id}`); //数据汇总-点击状态获取测量信息
  },
  getItemRemind(params) {
    return request.post("/scsl/bussMeasurementLocations/remind", params); //数据汇总-点击状态获取测量信息-提醒
  },
};
