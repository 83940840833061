import request from "@/utils/request";

export default {
  getMessage(params) {
    return request.post("/WebOffice/queryMessage", params);
  },
  clickMessage(params) {
    return request.put('/WebOffice', params);
  },
};
