import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Login2 from "../views/Login2.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "login",
  //   component: Login,
  // },
  {
    path: "/",
    name: "login",
    component: Login2,
  },
  {
    path: "/Demo",
    name: "Demo",
    component: () => import("../views/Demo.vue"),
  },
  {
    path: "/redirectUrl",
    name: "redirectUrl",
    component: () => import("../views/redirectUrl.vue"),
  },
  {
    path: "/drawingCanvas",
    name: "drawingCanvas",
    component: () => import("../views/DrawingCanvas.vue"),
  },
  {
    path: "/changeCompany",
    name: "changeCompany",
    component: () => import("../pages/itemChange/Index.vue"),
  },
  {
    path: "/companyRoute",
    name: "companyRoute",
    component: () => import("../pages/companyRoute/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "projectSwitch",
        name: "projectSwitch",
        component: () => import("@/components/ProjectSwitching.vue"),
        meta: [{ name: "项目切换" }],
      },
      {
        path: "homepage",
        name: "homepage",
        component: () => import("@/pages/homePage/HomePage.vue"),
        meta: [{ name: "数据看板" }],
      },
      // 路由配置
      // ProjectList
      {
        path: "projectList",
        name: "projectList",
        component: () => import("@/pages/projectManagement/ProjectList.vue"),
        // meta: [
        //   { name: "项目管理" },
        //   { name: "项目列表", url: "/home/projectList" },
        // ],
        meta: [{ name: "项目管理" }]
      },

      {
        path: "detailPage",
        name: "detailPage",
        component: () =>
          import("@/pages/projectManagement/projectList/ProjectDetails.vue"),
        meta: [
          { name: "项目管理" },
          // { name: "项目列表", url: "/home/projectList" },
          { name: "项目详情", url: "/home/detailPage" },
        ],
      },
      {
        path: "xmfj",
        name: "xmfj",
        component: () =>
          import("../pages/projectManagement/projectList/Projectbreakdown.vue"),
        meta: [
          { name: "项目管理" },
          // { name: "项目列表", url: "/home/projectList" },
          { name: "项目分解", url: "/home/xmfj" },
        ],
      },

      {
        path: "associatedDrawing",
        name: "associatedDrawing",
        component: () =>
          import("@/pages/projectManagement/AssociatedDrawings.vue"),
        meta: [
          { name: "项目管理" },
          { name: "关联图纸", url: "/home/associatedDrawing" },
        ],
      },
      {
        path: "positionList",
        name: "positionList",
        component: () =>
          import("@/pages/projectManagement/positionList.vue"),
        meta: [
          { name: "项目管理" },
          { name: "测量定位", url: "/home/positionList" },
        ],
      },
      {
        path: "measurementPositioning",
        name: "measurementPositioning",
        component: () =>
          import("@/pages/projectManagement/MeasurementPositioning.vue"),
        meta: [
          { name: "项目管理" },
          { name: "测量定位", url: "/home/measurementPositioning" },
        ],
      },
      //drawing
      {
        path: "drawingList",
        name: "drawingList",
        component: () => import("@/pages/drawingManagement/DrawingList.vue"),
        meta: [
          { name: "图纸管理" },
          { name: "图纸列表", url: "/home/drawingList" },
        ],
      },
      {
        path: "uploadDrawing",
        name: "uploadDrawing",
        component: () => import("@/pages/drawingManagement/UploadDrawing.vue"),
        meta: [
          { name: "图纸管理" },
          { name: "图纸列表", url: "/home/drawingList" },
          { name: "上传图纸", url: "/home/uploadDrawing" },
        ],
      },
      {
        path: "updateDrawing",
        name: "updateDrawing",
        component: () => import("@/pages/drawingManagement/UpdateDrawings.vue"),
        meta: [
          { name: "图纸管理" },
          { name: "图纸列表", url: "/home/drawingList" },
          { name: "更新图纸", url: "/home/updateDrawing" },
        ],
      },
      {
        path: "drawingDetail",
        name: "drawingDetail",
        component: () => import("@/pages/drawingManagement/DrawingDetail.vue"),
        meta: [
          { name: "图纸管理" },
          { name: "图纸列表", url: "/home/drawingList" },
          { name: "图纸详情", url: "/home/drawingDetail" },
        ],
      },
      {
        path: "historyVersion",
        name: "historyVersion",
        component: () => import("@/pages/drawingManagement/HistoryVersion.vue"),
        meta: [
          { name: "图纸管理" },
          { name: "图纸列表", url: "/home/drawingList" },
          { name: "历史版本", url: "/home/historyVersion" },
        ],
      },
      //data
      {
        path: "dataSummary",
        name: "dataSummary",
        component: () => import("@/pages/dataManagement/DataSummary.vue"),
        meta: [
          { name: "数据管理" },
          { name: "数据汇总", url: "/home/dataSummary" },
        ],
      },
      {
        path: "measuredData",
        name: "measuredData",
        component: () => import("@/pages/dataManagement/MeasuredData.vue"),
        meta: [
          { name: "数据管理" },
          { name: "实测数据", url: "/home/measuredData" },
        ],
      },
      {
        path: "measurementInformation",
        name: "measurementInformation",
        component: () =>
          import("@/pages/dataManagement/MeasurementInformation.vue"),
        meta: [
          { name: "数据管理" },
          { name: "实测数据", url: "/home/measuredData" },
          { name: "测量信息", url: "/home/measurementInformation" },
        ],
      },
      {
        path: "housementDetail",
        name: "housementDetail",
        component: () =>
          import("@/pages/dataManagement/measuredData/housementDetail.vue"),
        meta: [
          { name: "数据管理" },
          { name: "实测数据", url: "/home/measuredData" },
          { name: "测量信息", url: "/home/housementDetail" },
        ],
      },
      {
        path: "measurementDetail",
        name: "measurementDetail",
        component: () =>
          import("@/pages/dataManagement/measuredData/MeasurementDetail.vue"),
        meta: [
          { name: "数据管理" },
          { name: "实测数据", url: "/home/measuredData" },
          { name: "测量信息", url: "/home/measurementDetail" },
        ],
      },
      {
        path: "inspectInfomation",
        name: "inspectInfomation",
        component: () =>
          import("@/pages/dataManagement/InspectInformation.vue"),
        meta: [
          { name: "数据管理" },
          { name: "数据汇总", url: "/home/dataSummary" },
          { name: "检验批信息", url: "/home/inspectInfomation" },
        ],
      },
      {
        path: "otherInfomation",
        name: "otherInfomation",
        component: () =>
          import("@/pages/dataManagement/otherInfomation.vue"),
        meta: [
          { name: "数据管理" },
          { name: "数据汇总", url: "/home/dataSummary" },
          { name: "检验批信息", url: "/home/otherInfomation" },
        ],
      },
      //hot
      {
        path: "hotList",
        name: "hotList",
        component: () => import("@/pages/hotManagement/HotList.vue"),
        meta: [
          { name: "爆点管理" },
          // { name: "爆点列表", url: "/home/hotList" },
        ],
      },
      {
        path: "response",
        name: "response",
        component: () =>
          import("@/pages/hotManagement/RectificationResponse.vue"),
        meta: [
          { name: "爆点管理" },
          { name: "整改回复", url: "/home/response" },
        ],
      },
      {
        path: "responseInfomation",
        name: "responseInfomation",
        component: () =>
          import("@/pages/hotManagement/ResponseInfomation.vue"),
        meta: [
          { name: "爆点管理" },
          { name: "整改回复", url: "/home/response" },
          { name: "检验批信息", url: "/home/responseInfomation" },
        ],
      },
      {
        path: "hotInfomation",
        name: "hotInfomation",
        component: () => import("@/pages/hotManagement/HotInfomation.vue"),
        meta: [
          { name: "爆点管理" },
          // { name: "爆点列表", url: "/home/hotList" },
          { name: "检验批信息", url: "/home/hotInfomation" },
        ],
      },
      //sampingManagement
      // {
      //   path: "imageProgress",
      //   name: "imageProgress",
      //   component: () => import("@/pages/sampingManagement/ImageProgress.vue"),
      //   meta: [
      //     { name: "抽检管理" },
      //     { name: "形象进度", url: "/home/imageProgress" },
      //   ]
      // },
      {
        path: "sampingForm",
        name: "sampingForm",
        component: () => import("@/pages/sampingManagement/sampingForm.vue"),
        meta: [
          { name: "抽检管理" },
          { name: "抽检表格", url: "/home/sampingForm" },
        ],
      },
      {
        path: "sampingTask",
        name: "sampingTask",
        component: () => import("@/pages/sampingManagement/sampingTask.vue"),
        meta: [
          { name: "抽检管理" },
          { name: "抽检任务", url: "/home/sampingTask" },
        ],
      },
      {
        path: "samplingReport",
        name: "samplingReport",
        component: () => import("@/pages/sampingManagement/SamplingReport.vue"),
        meta: [
          { name: "抽检管理" },
          { name: "抽检结果", url: "/home/samplingReport" },
        ]
      },
      {
        path: "reportInfomation",
        name: "reportInfomation",
        component: () => import("@/pages/sampingManagement/reportInfomation.vue"),
        meta: [
          { name: "抽检管理" },
          { name: "抽检结果", url: "/home/samplingReport" },
          { name: "数据详情", url: "/home/reportInfomation" },
        ]
      },
      // {
      //   path: "samplingReport",
      //   name: "samplingReport",
      //   component: () => import("@/pages/sampingManagement/SamplingReport.vue"),
      //   meta: {
      //     title: "抽检报告",
      //   },
      // },
      //taskManagement
      {
        path: "detectionTask",
        name: "detectionTask",
        component: () => import("@/pages/taskManagement/DetectionTask.vue"),
        meta: [
          { name: "任务管理" },
          // { name: "检测任务", url: "/home/detectionTask" },
        ],
      },
      {
        path: "samplingTask",
        name: "samplingTask",
        component: () => import("@/pages/taskManagement/SamplingTask.vue"),
        meta: [
          { name: "任务管理" },
          { name: "抽检任务", url: "/home/samplingTask" },
        ],
        // meta: {
        //   title: "抽检任务",
        // },
      },
      //DetectionItems
      {
        path: "detectionItems",
        name: "detectionItems",
        component: () =>
          import("@/pages/DetectionItems/ProjectDetectionItems.vue"),
        meta: [
          { name: "检测表格" },
          // { name: "项目检测项", url: "/home/detectionItems" },
        ],
      },
      //systemManagement
      {
        path: "companySettings",
        name: "companySettings",
        component: () => import("@/pages/systemManagement/CompanySettings.vue"),
        meta: [
          { name: "系统管理" },
          { name: "企业设置", url: "/home/companySettings" },
        ],
      },
      {
        path: "roleManage",
        name: "roleManage",
        component: () => import("@/pages/systemManagement/RoleManage.vue"),
        meta: [
          { name: "系统管理" },
          { name: "角色管理", url: "/home/roleManage" },
        ],
      },
      {
        path: "personnelManagement",
        name: "personnelManagement",
        component: () =>
          import("@/pages/systemManagement/PersonnelManagement.vue"),
        meta: [
          { name: "系统管理" },
          { name: "人员管理", url: "/home/personnelManagement" },
        ],
      },
      {
        path: "processManagement",
        name: "processManagement",
        component: () =>
          import("@/pages/systemManagement/ProcessManagement.vue"),
        meta: [
          { name: "系统管理" },
          { name: "流程管理", url: "/home/processManagement" },
        ],
      },
      {
        path: "processInfo",
        name: "processInfo",
        component: () =>
          import("@/pages/systemManagement/processManagement/processInfo.vue"),
        meta: [
          { name: "系统管理" },
          { name: "流程管理", url: "/home/processManagement" },
          { name: "查看流程", url: "/home/processInfo" },
        ],
      },
      {
        path: "processDesign",
        name: "processDesign",
        component: () =>
          import("@/pages/systemManagement/processManagement/processDesign.vue"),
        meta: [
          { name: "系统管理" },
          { name: "流程管理", url: "/home/processManagement" },
          { name: "流程设计", url: "/home/processDesign" },
        ],
      },
      {
        path: "AccountSettings",
        name: "AccountSettings",
        component: () => import("@/pages/systemManagement/AccountSettings.vue"),
        meta: [
          { name: "系统管理" },
          { name: "账号设置", url: "/home/AccountSettings" },
        ],
      },
      // hardwareManagement
      {
        path: "hardwareManagement",
        name: "hardwareManagement",
        component: () =>
          import("@/pages/hardwareManagement/HardwareManagement.vue"),
        meta: [
          { name: "系统管理" },
          { name: "硬件管理", url: "/home/hardwareManagement" },
        ],
      },
      // helpCenter
      {
        path: "instruction",
        name: "instruction",
        component: () => import("@/pages/helpCenter/Instruction.vue"),
        meta: [
          { name: "帮助中心" },
          { name: "操作指南", url: "/home/instruction" },
        ],
      },
    ],
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
