import request from "@/utils/request";
export default {
  selectBlisterList(params) {
    return request.post("/scsl/locationData/selectBlisterList", params); //爆点列表-分页
  },
  initiateReview(params) {
    return request.post("/scsl/dataReview/initiateReview", params); //发起复查
  },
  changeTheRecord(params) {
    return request.post("/scsl/dataAbarbeitung/changeTheRecord", params); //整改记录
  },
  showChangeTheRecord(params) {
    return request.post("/scsl/dataAbarbeitung/showChangeTheRecord", params); //整改记录-查看
  },
  getPrincipal(params) {
    return request.post("/scsl/bussTask/getPrincipal", params); //获取负责人
  },
  rectificationReplyList(params) {
    return request.post(
      `/scsl/locationData/rectificationReplyList?pageNum=${params.params.pageNum}&pageSize=${params.params.pageSize}`,
      params.data
    ); //整改回复-分页
  },
  abarbeitung(params) {
    return request.post("/scsl/dataAbarbeitung/abarbeitung", params); //发起整改
  },
  breakpointInformation(params) {
    return request.post("/scsl/dataAbarbeitung/breakpointInformation", params); //爆点信息pdf查看
  },
  reviewDetails(params) {
    return request.post("/scsl/dataReview/reviewDetails", params); //复查信息pdf查看
  },
  rectificationInformation(params) {
    return request.post(
      "/scsl/dataAbarbeitung/rectificationInformation",
      params
    ); //整改信息
  },
  changeTheRecord(params) {
    return request.post("/scsl/dataAbarbeitung/changeTheRecord", params); //整改记录-查看
  },
  burstPointStatistic(params) {
    return request.post("/scsl/locationData/burstPointStatistics", params); //爆点列表-整改回复-顶部查看
  },
  startApproveProcess(params) {
    return request.post("/bpm/startApproveProcess", params); //发起整改-启动默认流程
  },
  queryApproveTree(params) {
    return request.post("/bpm/queryApproveTree", params); //发起整改-获取当前爆点流程下的整改人列表和复查人列表
  },
  downloadExcel(params) {
    return request.get(`/scsl/locationData/downloadExcel/${params.id}`); //下载
  },
  batchDownloadExcel(params) {
    return request.post('/scsl/locationData/batchDownloadExcel', params); //批量下载
  },
  batchPrintExcel(params) {
    return request.postFile('/scsl/locationData/print', params); //打印
  },
  getPeopleByRole(params) {
    return request.get(`/scsl/bussMeasurementLocations/getPeopleByRole/${params.id}`); //爆点列表-发起整改-整改人员
  },
  initiateRectification(params) {
    return request.post("/scsl/bussMeasurementLocations/initiateRectification", params); //爆点列表-发起整改
  },
  pushMeasurement(params) {
    return request.post('/scsl/bussMeasurementLocations/pushMeasurement', params); //批量推送
  },
  burstNotification(params) {
    return request.post("/scsl/scslBurstNotification/burstNotification", params); //爆点列表-爆点通知设置
  },
  getBurstNotification(params) {
    return request.get(`/scsl/scslBurstNotification/getBurstNotification/${params.xmId}`); //爆点列表-回显爆点通知设置
  },
  downloadExcelByOther(params) {
    return request.get(`/scsl/locationData/downloadExcelByOther/${params.id}`); //自定义-下载
  },
  batchDownloadExcelByOther(params) {
    return request.post('/scsl/locationData/batchDownloadExcelByOther', params); //自定义-批量下载
  },
  batchPrintExcelByOther(params) {
    return request.postFile('/scsl/locationData/printByOther', params); //自定义-打印
  },
};
