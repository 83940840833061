import request from "@/utils/request";

export default {
  toolList(params) {
    return request.post(
      `/scsl/baseMeasureTool/toolList?pageNum=${params.params.pageNum}&pageSize=${params.params.pageSize}`,
      params.data
    ); //硬件管理分页
  },
  saveToolBySaas(params) {
    return request.post("/scsl/baseMeasureTool/saveToolBySaas", params); //添加硬件-从平台导入
  },
  saveTool(params) {
    return request.post("/scsl/baseMeasureTool/saveTool", params); //添加硬件-新建
  },
  delTool(params) {
    return request.post("/scsl/baseMeasureTool/delTool", params); //删除硬件
  },
  updateTool(params) {
    return request.post("/scsl/baseMeasureTool/updateTool", params); //修改硬件
  },
  selectSaasTool(params) {
    return request.post("/scsl/baseMeasureTool/selectSaasTool", params); //查找sass后台硬件
  },
};
