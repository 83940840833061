import router from "@/router";
import axios from "axios";
import { Message } from "element-ui";
window.baseconfig = {
  pattern: true, //saas：true     私域部署：false
};
// const multiportUrl = "http://192.168.10.124:8081/ThreeTerminal";
// axios.defaults.baseURL = "http://192.168.10.229:8088"; //小金229 小牛226 江哥120
// const multiportUrl = "https://multiport.abuildingcloud.com/ThreeTerminal";
// axios.defaults.baseURL = "https://newscslapi.abuildingcloud.com"; //线上服务器

// 长业
const multiportUrl = ""; 
axios.defaults.baseURL = "https://changyeapi.abuildingcloud.com"; //线上服务器

// 中铁14
// const multiportUrl = "https://ztssmultiport.abuildingcloud.com/ThreeTerminal";
// axios.defaults.baseURL = "https://ztssscslapi.abuildingcloud.com"; //线上服务器
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"; // 设置跨域请求头
let Switch = true;
let lockState = true;


console.log('axios=======>', axios.defaults)
// 请求拦截器

axios.interceptors.request.use(config => {
  // 请求之前需要添加参数的方法
  // requestMethod(config)
  let token = "";
  if (sessionStorage.getItem("token")) {
    token = sessionStorage.getItem("token");
  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
//响应拦截器
axios.interceptors.response.use(
  (res) => {
    // 若账号重复登录，跳转到首页并提示
    if (res.data.code == 409) {
      sessionStorage.removeItem("token");
      router.push({
        path: "/",
      });
      if (Switch) {
        Message.error(res.data.msg);
      }
      Switch = false;
      setTimeout(() => {
        Switch = true;
      }, 1000);
    }
    else if (res.data.code == 401) {
      if (lockState) {
        lockState= false
        Message.error("登录过期，请重新登录");
        sessionStorage.removeItem("token");
        router.push({
          path: "/",
        });
        setTimeout(() => {
          lockState = true;
        }, 2000);
      }
    } else {
      return res.data;
    }
  },
  (err) => {
    console.log("err-------", err);
    // if(err.response.status==401){
    //     Message.error("登录已过期,请重新登录")
    //     router.push("/login")
    // }
  }
);
export { multiportUrl };
export default {
  post(url, param) {
    return axios({
      url: url,
      method: "POST",
      data: param,
    });
  },
  postFile(url, param) {
    return axios({
      url: url,
      method: "POST",
      responseType: "blob",
      data: param,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
    });
  },
  get(url, param) {
    return axios({
      url,
      method: "GET",
      params: param,
    });
  },
  getFile(url, param) {
    return axios({
      url,
      method: "GET",
      responseType: "blob",
      params: param,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
    });
  },
  delete(url, params) {
    return axios({
      url,
      method: "DELETE",
      params,
    });
  },
  deleteJson(url, params) {
    return axios({
      url,
      method: "DELETE",
      data: params,
    });
  },
  put(url, param) {
    return axios({
      url: url,
      method: "PUT",
      data: param,
    });
  },
};
