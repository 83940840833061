import request from "@/utils/request";

export default {
  queryProcess(params) {
    return request.post('/bpm/queryApproveProcess', params);//查询流程
  },
  addProcess(params) {
    return request.post('/bpm/insertApproveProcess', params);//添加流程信息
  },
  delProcess(params) {
    return request.post('/bpm/deleteApproveProcess', params);//删除流程信息
  },
  bindProcess(params) {
    return request.post('/bpm/bindingSingleApproveProcess', params);//流程绑定角色信息（角色IDS和角色名称组）
  },
  queryTypeDict(params) {
    return request.post('/bpm/queryWorkflowTypeDict', params);//获取流程类型数据字典
  },
  stopProcess(params) {
    return request.post('/bpm/disableApproveProcess', params);//停用流程
  },
  pubProcess(params) {
    return request.post('/bpm/publishApproveProcess', params);//发布流程
  },
  queryApproveDetails(params) {
    return request.post('/bpm/queryApproveProcessDetails', params);//查询流程节点信息
  },
};
