<template>
  <div class="loginPage">
    <div class="login-text">
      <div class="login-content">
        <h2>智能实测实量</h2>
        <span prefix-icon="iconfont icon-home"></span>
        <!-- 登录 -->
        <div class="login" v-show="operate == 1">
          <el-tabs
            v-model="activeName"
            :stretch="true"
            @tab-click="handleClick"
          >
            <!-- tab切换 -->
            <el-tab-pane label="密码登录" name="first">
              <el-form ref="form" :model="form" :rules="rules">
                <div class="accpas-login">
                  <el-form-item prop="userPhone">
                    <el-input
                      v-model="form.userPhone"
                      prefix-icon="el-icon-user-solid"
                      placeholder="请输入手机号码"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="userPas">
                    <el-input
                      v-model="form.userPas"
                      prefix-icon="el-icon-lock"
                      placeholder="请输入密码(6~18个字符)"
                      show-password
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div class="button">
                  <el-button
                    :loading="load"
                    type="primary"
                    @click="passwordLogin"
                    >登录</el-button
                  >
                  <el-button
                    class="wechatLogin"
                    type="primary"
                    @click="wxLogin"
                  >
                    <svg
                      style="width: 20px; height: 20px"
                      class="icon-font weChat"
                    >
                      <use xlink:href="#icon-wechat"></use>
                    </svg>
                    <span class="box">微信登录</span>
                  </el-button>
                </div>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="短信登录" name="second">
              <el-form ref="form2" :model="form2" :rules="rules2">
                <div class="tel-login">
                  <el-form-item prop="userPhone">
                    <el-input
                      v-model="form2.userPhone"
                      prefix-icon="el-icon-user-solid"
                      placeholder="请输入手机号码"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item class="inputCode" prop="code">
                    <el-input
                      v-model="form2.code"
                      prefix-icon="el-icon-lock"
                      placeholder="请输入验证码"
                    >
                      <template slot="append">
                        <el-button
                          class="getcodeBox"
                          :style="{
                            background: msg == '' ? '#2293f1' : '#909399',
                          }"
                          :disabled="isDisabled"
                          :loading="codeLoad"
                          @click="getCode()"
                        >
                          {{ isShow ? "获取验证码" : `${count}秒后获取` }}
                        </el-button>
                      </template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="button">
                  <el-button :loading="load1" type="primary" @click="telLogin"
                    >登录</el-button
                  >
                  <el-button
                    class="wechatLogin"
                    type="primary"
                    @click="wxLogin"
                  >
                    <svg
                      style="width: 20px; height: 20px"
                      class="icon-font weChat"
                    >
                      <use xlink:href="#icon-wechat"></use>
                    </svg>
                    <span class="box">微信登录</span>
                  </el-button>
                </div>
              </el-form>
            </el-tab-pane>
          </el-tabs>
          <div class="footer">
            <span @click="goRegister">注册账号</span>
            <span @click="forgetPas">忘记密码</span>
            <span @click="autoLogin">自动登录</span>
          </div>
        </div>
        <!-- 注册 -->
        <div class="register" v-show="operate == 2">
          <h3>注册账号</h3>
          <el-form ref="form3" :model="form3" :rules="rules3">
            <el-form-item prop="userPhone">
              <el-input
                v-model="form3.userPhone"
                prefix-icon="el-icon-user-solid"
                placeholder="请输入手机号码"
              >
              </el-input>
            </el-form-item>
            <el-form-item id="item" prop="userPas">
              <el-input
                v-model="form3.userPas"
                prefix-icon="el-icon-lock"
                placeholder="请输入密码(6~18个字符)"
                show-password
              >
              </el-input>
            </el-form-item>
            <el-form-item id="item" prop="makeSurePas">
              <el-input
                v-model="form3.makeSurePas"
                prefix-icon="el-icon-circle-check"
                placeholder="请再次输入密码"
                show-password
              >
              </el-input>
            </el-form-item>
            <el-form-item id="item" prop="company">
              <el-input
                v-model="form3.company"
                prefix-icon="el-icon-office-building"
                placeholder="请输入公司名"
              >
                <!-- <template slot="append">
                
              </template> -->
              </el-input>
            </el-form-item>
            <el-form-item prop="role">
              <el-select v-model="form3.role" placeholder="请选择角色">
                <el-option
                  v-for="(item, index) in roles"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="button">
            <el-button :loading="load" type="primary" @click="register"
              >免费注册</el-button
            >
          </div>
          <div>
            已有账号？<span @click="goLogin" class="goLogin">登录</span>
          </div>
        </div>
        <!-- 微信登录 -->
        <div class="wx-box" v-show="operate == 3">
          <p>
            请使用微信扫码登录<span class="return" @click="goLogin">返回</span>
          </p>
        </div>
        <!-- 忘记密码 -->
        <div class="forget"></div>
      </div>
    </div>
    <!--滑块验证 遮罩层 -->
    <div v-if="status" class="mask">
      <div class="slideContainer">
        <slide-verify
          @success="onSuccess"
          @fail="onFail"
          @refresh="onRefresh, closeMask"
          @fulfilled="onFulfilled"
          slider-text="向右滑动验证"
          :imgs="backgroundImages"
        >
        </slide-verify>
        <div style="margin-top: 15px">{{ text }}</div>
        <i @click="closeMask" class="el-icon-close icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { checkPhone } from "@/utils/validate";
export default {
  data() {
    // 手机号校验
    const validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!checkPhone(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };
    // 校验确认密码
    const validPas = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form3.userPas) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        userPhone: "",
        userPas: "",
      },
      form2: {
        userPhone: "",
        code: "",
      },
      form3: {
        userPhone: "",
        userPas: "",
        makeSurePas: "",
        company: "",
        role: "",
      },
      isShow: true,
      phoneCode: "",
      statusChange: "primary",
      isDisabled: false,
      msg: "",
      count: 0, //计数器
      roles: ["超级管理员", "检测员"],
      activeName: "first",
      dialogFormVisible: false,
      rules: {
        userPhone: [
          {
            required: true,
            trigger: "blur",
            validator: validPhone, //写入验证器
          },
        ],
        userPas: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
      },
      rules2: {
        userPhone: [
          {
            required: true,
            trigger: "blur",
            validator: validPhone, //写入验证器
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      rules3: {
        userPhone: [
          {
            required: true,
            trigger: "blur",
            validator: validPhone,
          },
        ],
        userPas: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
        makeSurePas: [
          {
            required: true,
            trigger: "blur",
            validator: validPas,
          },
        ],
        company: [
          {
            required: true,
            message: "请输入公司",
            trigger: "blur",
          },
        ],
        role: [{ required: true, message: "请选择角色", trigger: "change" }],
      },
      load: false,
      load1: false,
      codeLoad: false,
      text: "",
      backgroundImages: [
        "https://images.unsplash.com/photo-1543997570-71b6dfa36bb2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8JUU5JUEzJThFJUU2JTk5JUFGfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        "https://images.unsplash.com/photo-1508592948736-458bb110773b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8JUU5JUEzJThFJUU2JTk5JUFGfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
        "https://images.unsplash.com/photo-1611238411465-fc15c6e26574?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fCVFOSVBMyU4RSVFNiU5OSVBRnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        "https://images.unsplash.com/photo-1420582282039-a6d11404cb66?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8b3V0ZG9vcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
        "https://images.unsplash.com/photo-1501601962015-7f11b4445c43?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fG91dGRvb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        "https://images.unsplash.com/photo-1502394202744-021cfbb17454?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fG91dGRvb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
        "https://images.unsplash.com/photo-1516298773066-c48f8e9bd92b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDV8fG91dGRvb3J8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      ],
      status: false,
      operate: 1,
    };
  },
  mounted() {},
  methods: {
    // 账号密码登录
    passwordLogin() {
      this.$refs.form.validate((value) => {
        this.load = true;
        if (value) {
          const formData = new FormData();
          formData.append("username", this.form.userPhone);
          formData.append("password", this.form.userPas);
          this.$login
            .login(formData)
            .then((res) => {
              if (res.code == 200) {
                sessionStorage.setItem("token", res.token);
                sessionStorage.setItem('routeMsg', false)
                this.load = false;
                // sessionStorage.setItem("menu",  JSON.stringify(res.menu) );
                setTimeout(() => {
                  this.$router.push({
                    path: "/home/homepage",
                  });
                  this.$message.success("登录成功");
                }, 1000);
              }
            })
            .catch((err) => {
              this.load = false;
            });
        } else {
          this.$message.error("登录失败");
          setTimeout(() => {
            this.load = false;
          }, 1500);
        }
        setTimeout(()=>{
          this.load = false;
        },2000)
      });
    },
    // 手机登录
    telLogin() {
      this.load1 = true;
      this.$refs.form2.validate((value) => {
        if (value) {
          let result = this.$md5(this.form2.code) == this.phoneCode;
          // 判断验证码正确再发送接口登录
          if (result) {
            this.$login
              .xzmLogin({
                phone: this.form2.userPhone,
              })
              .then((res) => {
                sessionStorage.setItem("token", res.token);
                sessionStorage.setItem('routeMsg', false)
                this.$message.success("登录成功");
                setTimeout(() => {
                  this.load1 = false;
                  this.$router.push({
                    path: "/home/homepage",
                  });
                }, 1000);
              })
              .catch((err) => {
                this.$message.error(res.msg);
                setTimeout(() => {
                  this.load1 = false;
                }, 1500);
              });
          } else {
            this.$message.error("验证码错误");
            setTimeout(() => {
              this.load1 = false;
            }, 1500);
          }
        } else {
          this.$message.error("登录失败");
          setTimeout(() => {
            this.load1 = false;
          }, 1500);
        }
      });
    },
    // 微信登录
    wxLogin() {
      this.operate = 3;
    },
    //tab切换
    handleClick(tab, event) {
      // reset表单
      if (tab._data.index == "0") {
        this.$refs.form2.resetFields();
      } else if (tab._data.index == "1") {
        this.$refs.form.resetFields();
      }
    },
    //获取验证码按钮状态更改
    verification() {
      this.statusChange = "info";
      this.isDisabled = true;
      this.isShow = false;
      this.msg = "1";
      this.count = 6; //赋值6秒
      var times = setInterval(() => {
        this.count--;
        if (this.count <= 0) {
          // <=0 变成获取按钮
          this.isShow = true;
          this.statusChange = "primary";
          this.isDisabled = false;
          this.msg = "";
          clearInterval(times);
        }
      }, 1000);
    },
    forgetPas() {},
    autoLogin() {},
    goLogin() {
      this.operate = 1;
      this.$refs.form3.resetFields();
    },
    goRegister() {
      this.operate = 2;
      this.$refs.form.resetFields();
      this.$refs.form2.resetFields();
    },
    // 滑块验证
    onSuccess(times) {
      this.text =
        "验证通过，耗时 " +
        (times / 1000).toFixed(1) +
        "秒，" +
        " 请注意手机查收！";
      // 验证通过发送验证码
      this.$login
        .phoneLogin({
          phone: this.form2.userPhone,
        })
        .then((res) => {
          this.phoneCode = res.msg;
        })
        .catch((err) => {});
      this.verification();
      setTimeout(() => {
        this.status = false;
      }, 3000);
    },
    onFail() {
      this.text = "验证失败";
    },
    //点击刷新按钮
    onRefresh() {
      this.text = "";
    },
    //验证失败自动刷新
    onFulfilled() {
      this.text = "重新验证";
    },
    // 获取验证码
    getCode() {
      this.codeLoad = true;
      if (this.form2.userPhone) {
        const res = checkPhone(this.form2.userPhone);
        if (res) {
          this.status = true;
        }
      } else {
        this.$message.error("请输入手机号码");
      }
      setTimeout(() => {
        this.codeLoad = false;
      }, 1000);
    },
    closeMask() {
      this.status = false;
      this.text = "";
    },
    register() {
      this.$refs.form3.validate((value) => {
        if (value) {
          this.$message.success("注册成功");
          this.goLogin();
        } else {
          this.$message.error("注册失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" src="../assets/css/login.scss" ></style>
