import request from "@/utils/request";

export default {
  selectDwgcByXmbm(params) {
    return request.post("/scsl/dwGcgk/selectDwgcByXmbm", params); //根据项目编码查询单位工程
  },
  selectSurveyorForAddTask(params) {
    return request.post("/scsl/bussTask/selectSurveyorForAddTask", params); //获取项目下的测量人员
  },
  fbFxTree(params) {
    return request.post("/scsl/baseBranchSubitem/fbFxTree", params); //任务管理，查询分部分项数据
  },
  selectByFxId(params) {
    return request.post("/scsl/bussInspectionlot/selectByFxId", params); //根据分项id获取检验批
  },
  taskList(params) {
    return request.post(
      `/scsl/bussTask/taskList?pageNum=${params.params.pageNum}&pageSize=${params.params.pageSize}`,
      params.data
    ); //任务列表分页
  },
  selectJypObject(params) {
    return request.post("/scsl/bussTask/selectJypObject", params); //添加任务-获取检验批对象
  },
  selectMeasurementLocations(params) {
    return request.post("/scsl/bussTask/selectMeasurementLocations", params); //添加任务-获取检验批部位
  },
  insertTask(params) {
    return request.post("/scsl/bussTask/insertTask", params); //添加检测任务
  },
  showTasks(params) {
    return request.post("/scsl/bussTask/showTask", params); //查看检测任务
  },
  updateTask(params) {
    return request.post("/scsl/bussTask/updateTask", params); //修改检测任务
  },
  cancelTask(params) {
    return request.deleteJson("/scsl/bussTask/cancelTask", params); //取消删除任务
  },
  selectFloorByDwgc(params) {
    return request.post("/scsl/bussFloor/selectFloorByDwgc", params); //通过单位工程获取楼层
  },
  getJcxByMeddle(params) {
    return request.post("/scsl/levelCheckMiddle/getJcxByMeddle", params); //根据容量对象获取检测项
  },
  selectBussMeasurement(params) {
    return request.post("/scsl/bussMeasurementLocations/selectBussMeasurement", params); //查找检验批部位
  },
  getOtherTaskList(params) {
    return request.post("/scsl/baseOtherTask/OtherTaskList", params); //自定义检测-检测任务分页列表
  },
  insertSpringBackTask(params) {
    return request.post("/scsl/baseOtherTask/insertSpringBackTask", params); //创建回弹表格任务
  },
  getTestItemByTableName(params) {
    return request.post("/scsl/baseOtherTask/getTestItemByTableName", params); //根据表格名称获取检测项
  },
  getRecordByXmId(params) {
    return request.post("/scsl/baseOtherTask/getRecordByXmId", params); //根据项目id和字符模糊查询记录编号
  },
  insertOtherTask(params) {
    return request.post("/scsl/baseOtherTask/insertOtherTask", params); //创建其他表格任务(除了回弹表的另外两张)
  },
  showBGSpringBackTaskDetails(params) {
    return request.post("/scsl/baseOtherTask/showBGSpringBackTaskDetails", params); //查看回弹表任务
  },
  showBGOtherTaskDetails(params) {
    return request.post("/scsl/baseOtherTask/showBGOtherTaskDetails", params); //查看自定义检测其他表格任务（除回弹表）
  },
  // removeBGSpringBackTask(params) {
  //   return request.post("/scsl/baseOtherTask/removeBGSpringBackTask", params); //取消自定义检测回弹表任务
  // },
  // removeBGOtherTask(params) {
  //   return request.post("/scsl/baseOtherTask/removeBGOtherTask", params); //取消自定义检测其他表格任务（除回弹表）
  // },
  updateBGSpringBackTask(params) {
    return request.post("/scsl/baseOtherTask/updateBGSpringBackTask", params); //修改自定义检测回弹表任务
  },
  updateBGOtherTask(params) {
    return request.post("/scsl/baseOtherTask/updateBGOtherTask", params); //修改自定义检测其他表格任务（除回弹表）
  },
  downloadPdfByExcel(params) {
    return request.post("/scsl/baseOtherTask/downloadPdfByExcel", params); //自定义检测数据汇总分页-下载
  },
  insertOtherTaskBatchByTask(params) {
    return request.post("/scsl/baseOtherTask/insertOtherTaskBatchByTask", params); //创建其他表格任务(宁波表格)
  },
  removeBGOtherTask(params) {
    return request.deleteJson("/scsl/baseOtherTask/removeBGOtherTask", params); //取消自定义检测其他表格任务
  },
};

