import request from "@/utils/request";

export default {
  selectMajor(params) {
    return request.get("/scsl/level/selectMajor", params); //查询所有工程类别
  },
  testItemTree(params) {
    return request.post("/scsl/level/testItemTree", params); //项目检测项页面-树形结构
  },
  selectDetailed(params) {
    return request.post("/scsl/level/selectDetailed", params); //项目检测项页面-表结构和测量项数据
  },
  selectByLevelAndCheck(params) {
    return request.post("/scsl/level/selectExistConnectByLevelAndCheck", params); //项目检测项页面-单位和检测项页面
  },
  selectAllDirectory(params) {
    return request.post("/scsl/baseOtherDirectory/selectAllDirectory", params); //项目检测项页面-自定义检测-查看公司下其他表格的所有目录
  },
  insertDirectory(params) {
    return request.post("/scsl/baseOtherDirectory/insertDirectory", params); //项目检测项页面-自定义检测-添加目录
  },
  updateDirectory(params) {
    return request.post("/scsl/baseOtherDirectory/updateDirectory", params); //项目检测项页面-自定义检测-修改目录
  },
  uploadOtherTable(params) {
    return request.post("/scsl/baseOtherDirectory/uploadOtherTable", params); //项目检测项页面-自定义检测-添加表格
  },
  getLuckySheetAndTestItem(params) {
    return request.post("/scsl/baseOtherDirectory/getLuckySheetAndTestItem", params); //项目检测项页面-自定义检测-点击出表
  },
  getTestItemByTableName(params) {
    return request.post("/scsl/baseOtherTask/getTestItemByTableName", params); //项目检测项页面-自定义检测-根据表格名称获取检测项
  },
  getExcel(params) {
    console.log('params==================>', params)
    return request.getFile(`/scsl/baseOtherDirectory/getExcel/${params.id}`); //项目检测项页面-自定义检测-点击表格获取表格数据
  },
  delExcel(params) {
    return request.post(`/scsl/baseOtherDirectory/delExcel/${params.id}`); //项目检测项页面-自定义检测-删除表格或者目录
  },
  querybranch(params) {
    return request.post("/branch/querybranch", params); //项目检测项页面-树形结构
  },
  queryleveldetailed(params) {
    return request.post("/branch/queryleveldetailed", params); //检测表格-检验批检测点击出表
  },
};
