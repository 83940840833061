import request from "@/utils/request";

export default {
  roleList(params) {
    return request.post("/system/roles/list", params);//角色管理-分页
  },
  userMenutree(params) {
    return request.post('/system/roles/menutree', params);//角色管理-查询角色拥有的权限
  },
  addRolesByName(params) {
    return request.post('/system/roles/add', params);//角色管理-添加角色
  },
  updateRolesByName(params) {
    return request.post('/system/roles/edit', params);//角色管理-修改角色
  },
  deleteRolesById(params) {
    return request.post('/system/roles/remove', params);//角色管理-删除角色
  },
  setRoleRoles(params) {
    return request.post('/system/roles/editrolemenu', params);//角色管理-修改角色权限
  },
  getDataPermission() {
    return request.get('/system/roles/dataPermission');//角色管理-数据权限下拉框
  },
  setDataRoles(params) {
    return request.post('/system/roles/editdatascope', params);//角色管理-修改角色数据权限
  },
};
