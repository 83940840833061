import request from "@/utils/request";

export default {
  getCompany(params) {
    return request.post("/scsl/companyInfo/getCompany", params);//企业设置
  },
  updateCompany(params) {
    return request.post("/scsl/companyInfo/updateCompany", params);//修改企业
  },
  peopleList(params) {
    return request.post("/scsl/companyInfo/peopleList", params);//人员管理-分页
  },
  userList(params) {
    return request.post("/systemmanage/user/list", params);//人员管理-查询用户
  },
  deleteUser(params) {
    return request.post('/systemmanage/user/remove', params);//人员管理-删除用户
  },
  addUser(params) {
    return request.post('/systemmanage/user', params);//人员管理-新增用户
  },
  editUser(params) {
    return request.post('/systemmanage/user/edit', params);//人员管理-编辑用户
  },
  editpassword(params) {
    return request.post('/systemmanage/user/editpassword', params);//人员管理-重置密码
  },
};
