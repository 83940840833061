import request from "@/utils/request";

export default {
  querySampingCatalog(params) {
    return request.post('/check/list', params);//查询
  },
  addSampingCatalog(params) {
    return request.post('/check', params);//添加目录
  },
  updateSampingCatalog(params) {
    return request.put('/check', params);//修改目录
  },
  deleteSampingCatalog(params) {
    return request.delete(`/check/${params.id}`);//删除目录
  },
  addSampingTable(params) {
    return request.post('/check/addtable', params);//添加目录
  },
  deleteSampingTable(params) {
    return request.delete(`/check/table/${params.id}`);//删除表格
  },
  getSampingTask(params) {
    return request.get("/check/task/list", params); //抽检管理-查询抽检任务
  },
  addSampingTask(params) {
    return request.post("/check/task", params); //抽检管理-添加抽检任务
  },
  updateSampingTask(params) {
    return request.put("/check/task", params); //抽检管理-编辑抽检任务
  },
  deleteSampingTask(params) {
    return request.delete(`/check/task/${params}`); //抽检管理-删除抽检任务
  },
  getSampingReport(params) {
    return request.get("/check/task/result/list", params); //抽检管理-查询抽检报告
  },
  getReportInfo(params) {
    return request.get("/check/task/result/view", params); //抽检管理-抽检报告查看
  },
  printSampingReport(params) {
    return request.postFile("/print/batchprint", params); //抽检管理-单张打印
  },
  downloadSampingReport(params) {
    return request.postFile("/print/downloaoneexcel", params); //抽检管理-单张下载
  },
};
