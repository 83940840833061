import request from "@/utils/request";

export default {
  selectByXmId(params) {
    return request.post("/scsl/xmTz/selectByXmId", params); //查找当前项目下所有图纸
  },
  relevanceTz(params) {
    return request.post("/scsl/xmTz/relevanceTz", params); //关联选择的图纸
  },
  updateTz(params) {
    return request.post("/scsl/xmTz/updateTz", params); //修改图纸(关联图纸使用)
  },
  updateName(params) {
    return request.post("/scsl/xmTz/updateName", params); //修改名称
  },
  batchRelevance(params) {
    return request.post("/scsl/xmTz/batchRelevance", params); //批量关联图纸
  },
  tzList(params) {
    return request.post("/scsl/xmTz/tzList", params); //图纸列表
  },
  uploadCad(params) {
    return request.post("/scsl/xmTz/uploadCad", params); //图纸上传
  },
  saveTz(params) {
    return request.post("/scsl/xmTz/saveTz", params); //保存图纸
  },
  selectTz(params) {
    return request.post("/scsl/xmTz/selectTz", params); //图纸列表-查看
  },
  updateTzForHistory(params) {
    return request.post("/scsl/xmTz/updateTzForHistory", params); //图纸列表-编辑-更新
  },
  delTz(params) {
    return request.post("/scsl/xmTz/delTz", params); //删除图纸
  },
  historyList(params) {
    return request.post("/scsl/xmTz/historyList", params); //历史图纸-分页
  },
  selectByFloor(params) {
    return request.post("/scsl/xmTz/selectByFloor", params); //根据楼层获取图纸
  },
  downloadTz(params) {
    return request.post("/scsl/xmTz/downloadTz", params); //图纸下载
  },
  download(params) {
    return request.post("/scsl/xmTz/download", params); //minio下载图纸
  },
  drawUploadPreview(params) {
    return request.postFile("/scsl/xmTz/file/preview", params); //图纸上传并预览
  },
  getCADUrl(params) {
    return request.postFile("/scsl/xmTz/savefile/preview", params); //查看图纸详情预览
  },
  delfileversion(params) {
    return request.post("/scsl/xmTz/delfileversion", params); //删除历史版本
  },
  batchRelevance(params) {
    return request.post("/scsl/xmTz/batchRelevance", params); //批量关联图纸
  },
};
