import request from "@/utils/request";

export default {
  getXmPage(params) {
    return request.post("/scsl/xmGcgk/getXmPage", params);
  },
  addPeopleList(params) {
    return request.post("/scsl/xmGcgk/addPeopleList", params);
  },
  peopleManagement(params) {
    return request.post("/scsl/xmGcgk/peopleManagement", params);
  },
  addPeopleFromItem(params) {
    return request.post("/scsl/xmGcgk/addPeopleFromItem", params);
  },
  delPeopleFromItem(params) {
    return request.post("/scsl/xmGcgk/delPeopleFromItem", params);
  },
  upload(params) {
    return request.post("/scsl/xmGcgk/upload", params);
  },
  xjxm(params) {
    return request.post("/scsl/xmGcgk/xjxm", params);
  },
  xmxq(params) {
    return request.post("/scsl/xmGcgk/xmxq", params);
  },
  getXmTree(params) {
    return request.post("/scsl/xmGcgk/getXmTree", params);
  },
  getXmByCompany(params) {
    return request.post("/scsl/xmGcgk/getXmByCompany", params);
  },
  saveItemLocation(params) {
    return request.post("/scsl/xmGcgk/saveItemLocation", params); //保存历史记录
  },
  getItemLocation(params) {
    return request.post("/scsl/xmGcgk/getItemLocation", params); //获取历史记录
  },
  // 项目分解
  itemDecompose(params) {
    return request.post("/scsl/xmGcgk/itemDecompose", params); //项目分解页面
  },
  saveDwgcFloor(params) {
    return request.post("/scsl/dwGcgk/saveDwgcFloor", params); //新建单位工程楼层
  },
  saveFloor(params) {
    return request.post("/scsl/bussFloor/saveFloor", params); //添加单层
  },
  delFloor(params) {
    return request.post("/scsl/bussFloor/delFloor", params); //删除楼层
  },
  delDwgc(params) {
    return request.post("/scsl/bussFloor/delDwgc", params); //删除单位工程
  },
  selectGcgk(params) {
    return request.post("/scsl/dwGcgk/selectGcgk", params); //项目数据同步到单位工程数据
  },
  queryunitworks(params) {
    return request.post("/scsl/dwGcgk/queryunitworks", params); //项目分解-查询单位工程与楼层
  },
  saveunitworks(params) {
    return request.post("/scsl/dwGcgk/saveunitworks", params); //项目分解-新建单位工程
  },
  bussAddFloor(params) {
    return request.post("/scsl/bussFloor", params); //项目分解-新建楼层
  },
  delDwGcgk(params) {
    return request.post("/scsl/dwGcgk/delDwGcgk", params); //删除单位工程
  },
  renameDwGcgk(params) {
    return request.post("/scsl/dwGcgk/rename", params); //单位工程重命名
  },
  threeFbFxTree(params) {
    return request.post("/scsl/baseBranchSubitem/threeFbFxTree", params); //查询三大类分部分项数据
  },
  getListData(params) {
    return request.get("/system/pnt/list", params); //查询测量定位
  },
  deleteList(params) {
    return request.delete(`/system/pnt/${params}`); //删除测量定位
  },
  selectinspectioncapacity(params) {
    return request.post("/scsl/bussTask/selectinspectioncapacity", params); //项目管理-测量定位-检验批-获取容量对象
  },
  selectinspectiondetection(params) {
    return request.post("/scsl/levelCheckMiddle/selectinspectiondetection", params); //项目管理-测量定位-检验批-容量-获取检测项
  },
  addMeasurementPosition(params) {
    return request.post("/system/pnt", params); //项目管理-新增测量定位
  },
  upDateMeasurementPosition(params) {
    return request.put("/system/pnt", params); //项目管理-编辑修改测量定位
  },
  getdrawing(params) {
    return request.post("/scsl/bussFloor/getdrawing", params); //项目管理-测量定位-查询单位工程-楼层图纸
  },
  queryPosition(params) {
    return request.get(`/system/pnt/position?positionName=${params.positionName}`); //项目管理-测量定位-查询部位
  },
  selectDwgc(params) {
    return request.post("/scsl/dwGcgk/selectDwgc", params); //查询单位工程概况
  },
};
